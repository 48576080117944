import { useRef, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "./authSlice";
import { useLoginMutation } from "./authApiSlice";
import usePersist from "../../hooks/usePersist";
import { ToastContainer, toast } from "react-toastify";
import Reaptcha from "reaptcha";
import Axios from "axios";

const Login = () => {
  const emailRef = useRef();
  const errRef = useRef();
  //catpcha Ref
  const captchaRef = useRef(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [persist, setPersist] = usePersist();
  const [captchaToken, setCaptchaToken] = useState(null);
  const [signInButtonDisplay, setSignInButtonDisplay] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();

  useEffect(() => {
    emailRef.current.focus();
  }, []);

useEffect(() => {
  const verifyCaptcha = async () => {
    try {
      const { data } = await Axios.post(
        "https://nz-price-promise-11646-api-prod.azurewebsites.net/api/v1/auth/captcha",
        { captchaToken }
      );
      if (data.message === "Robot") {
        setSignInButtonDisplay(false);
      } else {
        setSignInButtonDisplay(true);
      }
    } catch (error) {
      console.error("Failed to verify captcha:", error);
      // Handle the error as needed
    }
  };

  verifyCaptcha();
}, [captchaToken, signInButtonDisplay]);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const verifyCaptchaToken = () => {
    captchaRef.current.getResponse().then((res) => {
      setCaptchaToken(res);
      // console.log(res)
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { accessToken } = await login({ email, password }).unwrap();

      dispatch(setCredentials({ accessToken }));

      setEmail("");
      setPassword("");
      navigate("/dashboard");
    } catch (err) {
      if (!err.status) {
        toast("No Server Response");
      } else if (err.status === 400) {
        toast("Missing Username or Password");
      } else if (err.status === 401) {
        toast("Unauthorized");
      } else {
        toast(err.data?.message);
      }
      // errRef.current.focus();
    }
  };

  const handleEmailInput = (e) => setEmail(e.target.value);
  const handlePwdInput = (e) => setPassword(e.target.value);
  const handleToggle = () => setPersist((prev) => !prev);


  if (isLoading) return <p>Loading...</p>;

  const content = (
    <div className="bg-text-gray-900">
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <ToastContainer
                position="top-center"
                autoClose={1000}
                limit={1}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                theme="light"
              />
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  E-mail
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    ref={emailRef}
                    autoComplete="off"
                    onChange={handleEmailInput}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="off"
                    onChange={handlePwdInput}
                    // value={password}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    onChange={handleToggle}
                    checked={persist}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-3 block text-sm leading-6 text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                {/* <div className="text-sm leading-6">
                  <a
                    href="#"
                    className="font-semibold text-blue-600 hover:text-blue-500"
                  >
                    Forgot password?
                  </a>
                </div> */}
              </div>
              <div className="mt-5">
                <Reaptcha
                  sitekey="6LeCEiIqAAAAAP9tIRDH6XKWF36nZ_m8EKAWtsAt"
                  ref={captchaRef}
                  onVerify={verifyCaptchaToken}
                />
              </div>

              <div>
                {signInButtonDisplay === false ? (
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm cursor-not-allowed"
                    disabled
                  >
                    Sign in
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Sign in
                  </button>
                )}
              </div>
            </form>

            <div>
              <div className="relative mt-10">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-200" />
                </div>
              </div>
            </div>
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            <Link to="/">Back to Home</Link>
          </p>
        </div>
      </div>
    </div>
  );

  return content;
};

export default Login;
