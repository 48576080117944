import React from 'react'
import {useNavigate} from "react-router-dom"

import {useSelector} from "react-redux"
import { selectUserById, useDeleteUserMutation } from "./usersApiSlice";


const User = ({userId}) => {
  const user = useSelector((state) => selectUserById(state,userId));
 

  // const {firstName, lastName, email, role } = user;

  const navigate = useNavigate();

  if(user){
     const handleEdit = () => navigate(`/dashboard/users/${userId}`);
      return (
        <tr>
          <td className="whitespace-nowrap py-4 pl-5 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
            {user.userId}
          </td>
          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            {user.firstName}
          </td>
          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            {user.lastName}
          </td>
          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            {user.email}
          </td>
          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            {user.role}
          </td>
          {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"></span>
          </td> */}
          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <a className="text-indigo-600 hover:text-indigo-900">
              <span className="cursor-pointer" onClick={handleEdit}>
                View
              </span>

              {/* <span className="cursor-pointer ml-5 text-red-600 hover:text-indigo-900">
                Delete
              </span> */}
              <span className="sr-only"></span>
            </a>
          </td>
        </tr>
      );
  } else return null;



 
}

export default User