import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const imagesAdapter = createEntityAdapter({});

const initialState = imagesAdapter.getInitialState();

export const uploadImagesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getImages: builder.query({
      query: () => `/upload/all-images`,
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
      transformResponse: (responseData) => {
        const loadedImages = responseData.map((image) => {
          image.id = image.imgId;
          return image;
        });
        return imagesAdapter.setAll(initialState, loadedImages);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Images", imgId: "LIST" },
            ...result.ids.map((id) => ({ type: "Images", id })),
          ];
        } else return [{ type: "Images", imgId: "LIST" }];
      },
    }),
   
  }),
});

export const { useGetUploadImagesQuery } = uploadImagesApiSlice;
// returns the query result object
export const selectUploadImagesResult =
  uploadImagesApiSlice.endpoints.getImages.select();

// creates memoized selector
const selectUploadImagesData = createSelector(
  selectUploadImagesResult,
  (imageResult) => imageResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllImages,
  selectById: selectImageById,
  selectIds: selectImageIds,
  // Pass in a selector that returns the tickets slice of state
} = imagesAdapter.getSelectors(
  (state) => selectUploadImagesData(state) ?? initialState
);
