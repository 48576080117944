import { useNavigate, useParams } from "react-router-dom";
import { selectUserById, useUpdateUserMutation } from "../users/usersApiSlice";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ROLES } from "../../config/Roles";
import { ToastContainer, toast } from "react-toastify";

const EditProfileForm = () => {
  const { userId } = useParams();
  const user = useSelector((state) => selectUserById(state, userId));
  const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const PWD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  //initial change password email   /  validate password and email
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(user.email);
  const [validPassword, setValidPassword] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [role, setRole] = useState(user.role);

 

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
  }, [password]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(user.email));
  }, [email]);

  //  const [
  //    sendLogout,
  //    {
  //      isLogoutSuccess: isSuccess,
  //      isLogoutLoading: isLoading,
  //      isLogoutError: isError,
  //      logoutError: error,
  //    },
  //  ] = useSendLogoutMutation();
  
const [updateUser, { isLoading, isSuccess, isError, error }] =
  useUpdateUserMutation();

  const navigate = useNavigate();

  const handleCancelNavigate = () => {
    window.location.reload();
  };

  // useEffect(() => {
  //   if (isSuccess) {
  //     // setRole();

  //     navigate(`/dashboard`);
  //   }
  // }, [isSuccess]);
  // useEffect(() => {
  //   if (isLogoutSuccess) navigate("/");
  // }, [isLogoutSuccess, navigate]);

  if (isSuccess) {
    toast("Data Updated Successfully!");
    setTimeout(() => {
       navigate(`/`);
   
    }, 1500);
  }

  if (isError) {
    toast("Data Update fail!", error);
    setTimeout(() => {
      navigate(`/dashboard/`);
    }, 1500);
  }

  const onPasswordChange = (e) => setPassword(e.target.value);
  const onEmailChange = (e) => setEmail(e.target.value);

  // const onRoleChanged=e=>{
  //   const values = Array.from(
  //           e.target.selectedOptions,
  //           (option) => option.value
  //       )
  //       setRole(values)
  //   }
  const onRoleChanged = (e) => {
    // const values = Array.from(
    //         e.target.selectedOptions,
    //         (option) => option.value
    //     )
    setRole(e.target.value);
  };

  // console.log("role", role);

  const onSaveUserClicked = async (e) => {
    if (password) {
      await updateUser({
        userId: userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: email,
        password: password,
        role: role,
      });
    } else {
      await updateUser({
        userId: userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: email,
        role: role,
      });
      navigate(`/dashboard`);
    }
  };

  //   const options = Object.values(ROLES).map((role) => {
  //     return (
  //       <option key={role} value={role}>
  //         {role}
  //       </option>
  //     );
  //   });

  // let canSave
  //  if (password) {
  //    canSave =
  //      [role.length, validEmail, validPassword].every(Boolean) &&
  //      !isLoading;
  //  } else {
  //    canSave = [role.length, validEmail].every(Boolean) && !isLoading;
  //  }

  const validEmailClass = !validEmail ? <p>Please enter validate Email</p> : "";
  const validPwdClass =
    password && !validPassword ? (
      <p>
        Please enter validate password.At least one lowercase letter, one
        uppercase letter, one numeric digit, and one special character, and that
        it be eight characters or longer.
      </p>
    ) : (
      ""
    );

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          limit={1}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
          theme="light"
        />
        <div className="space-y-12 sm:space-y-16">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              User Information
            </h2>

            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  First name
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    value={user.firstName}
                    disabled
                    type="text"
                    name="first-name"
                    id="first-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Last name
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    value={user.lastName}
                    disabled
                    type="text"
                    name="last-name"
                    id="last-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Email address
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    defaultValue={user.email}
                    id="email"
                    name="email"
                    type="email"
                    onChange={onEmailChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                  />
                </div>
              </div> */}

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Change Password
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    onChange={onPasswordChange}
                    type="password"
                    name="password"
                    id="password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  />
                </div>
                {validPwdClass}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={handleCancelNavigate}
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={onSaveUserClicked}
            className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default EditProfileForm;
