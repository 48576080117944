import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireAuthAdmin = () => {
  const location = useLocation();
  const { role } = useAuth();



  const content=(role==="Admin")?(<Outlet/>):(<Navigate to="/login" state={{ from: location }} replace />);

  return content;
};



export default RequireAuthAdmin;
