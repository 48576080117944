import { useSelector } from "react-redux";
import { selectCurrentToken } from "../features/auth/authSlice";
import jwtDecode from "jwt-decode";

const useAuth = () => {
  const token = useSelector(selectCurrentToken);
  let isAgent = false;
  let isAdmin = false;
  let status = "newUser";
  let firstName="";
  let lastName="";
  let userId=""

  if (token) {
    const decoded = jwtDecode(token);
    const { email, role ,firstName,lastName,userId} = decoded.UserInfo;

    isAgent = role.includes("Agent");
    isAdmin = role.includes("Admin");

    if (isAgent) status = "Agent";
    if (isAdmin) status = "Admin";

    return { email, role, status, isAgent, isAdmin, firstName, lastName,userId };
  }

  return {
    email: "",
    role: "",
    isAgent,
    isAdmin,
    status,
    firstName,
    lastName,
    status,
    userId,
  };
};
export default useAuth;
