import {Outlet} from 'react-router-dom'
import {useNavigate,useLocation,Link,useParams} from 'react-router-dom'

import { Fragment, useState,useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

import {useSendLogoutMutation} from "../features/auth/authApiSlice"
import useAuth from "../hooks/useAuth"

const DASH_REGEX = /^\/dashboard(\/)?$/;
const TICKETS_REGEX = /^\/dashboard\/tickets(\/)?$/;
const USERS_REGEX = /^\/dashboard\/users(\/)?$/;

// const navigation = [
//   { name: "Dashboard", href: "/dashboard", icon: HomeIcon, current: true },
//   { name: "Team", href: "/dashboard/users", icon: UsersIcon, current: false },
//   { name: "Tickets", href: "/dashboard/tickets", icon: DocumentDuplicateIcon, current: false },
// ];

const userNavigation = [
  // { name: "Your profile", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



const DashboardLayout = () => {

  const params=useParams()
  // console.log("params",params);
  const {firstName,lastName,role,isAdmin,isAgent,email,userId}=useAuth()

  const navigate = useNavigate()
  const {pathname} = useLocation()
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [sendLogout, { isSuccess, isLoading, isError, error }] =useSendLogoutMutation();

  const [isDashboardButtonActive, setIsDashboardButtonActive] = useState(true);
  const [isUsersButtonActive, setIsUsersButtonActive] = useState(false);
  const [isTicketsButtonActive, setIsTicketsButtonActive] = useState(false);

  const handleDashboardButtonActive = () => {
    setIsDashboardButtonActive(true)
    setIsUsersButtonActive(false)
    setIsTicketsButtonActive(false)
  };

  const handleUsersButtonActive = () => {
      setIsDashboardButtonActive(false);
      setIsUsersButtonActive(true);
      setIsTicketsButtonActive(false);
  };

  const handleTicketsButtonActive = () => {
     setIsDashboardButtonActive(false);
     setIsUsersButtonActive(false);
     setIsTicketsButtonActive(true);
  };

   useEffect(() => {
     if (isSuccess) navigate("/");
   }, [isSuccess, navigate]);

   if (isLoading) return <p>Logging Out...</p>;

   if (isError) return <p>Error: {error.data?.message}</p>;

   let dashClass = null;
   if (
     !DASH_REGEX.test(pathname) &&
     !TICKETS_REGEX.test(pathname) &&
     !USERS_REGEX.test(pathname)
   ) {
    //  dashClass = "dash-header__container--small";
    // navigate("/");
   }

  const onGoHomeClicked=()=>navigate("/")
  const onDashboardClicked = () => navigate("/dashboard");
  const onGoTicketsClicked=()=>navigate("/dashboard/tickets")
  const onGoUsersClicked=()=>navigate("/dashboard/users")

  // const logoutButton = (
  //   // <Menu.Item>
  //   //   <a
  //   //     className="bg-gray-50 block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer"
  //   //     onClick={sendLogout}
  //   //     herf="#"
  //   //   >
  //   //     Logout
  //   //   </a>
  //   // </Menu.Item>
  //   <a
  //     className="bg-gray-50 block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer"
  //     onClick={sendLogout}
  //   >
  //     Logout
  //   </a>
  // );

 

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {/*Dashboard */}
                            <li>
                              <Link
                                onClick={handleDashboardButtonActive}
                                to="/dashboard"
                                className={
                                  !isDashboardButtonActive
                                    ? "text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    : "bg-gray-800 text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                }
                              >
                                <HomeIcon
                                  className="h-6 w-6 shrink-0"
                                  aria-hidden="true"
                                />
                                Dashboard
                              </Link>
                            </li>

                            {isAdmin ? (
                              <li>
                                <Link
                                  to="/dashboard/users"
                                  onClick={handleUsersButtonActive}
                                  className={
                                    !isUsersButtonActive
                                      ? "text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                      : "bg-gray-800 text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  }
                                >
                                  <UsersIcon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  Teams
                                </Link>
                              </li>
                            ) : (
                              <></>
                            )}
                            {isAdmin || isAgent ? (
                              <li>
                                <Link
                                  to="/dashboard/tickets"
                                  onClick={handleUsersButtonActive}
                                  className={
                                    !isTicketsButtonActive
                                      ? "text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                      : "bg-gray-800 text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  }
                                >
                                  <DocumentDuplicateIcon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  Tickets
                                </Link>
                              </li>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <p style={{ color: "white" }} onClick={onGoHomeClicked}>
                Price Promise
              </p>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {/*Dashboard */}
                    <li>
                      <Link
                        to="/dashboard"
                        onClick={handleDashboardButtonActive}
                        className={
                          !isDashboardButtonActive
                            ? "text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            : "bg-gray-800 text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        }
                      >
                        <HomeIcon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        Dashboard
                      </Link>
                    </li>

                    {isAdmin ? (
                      <li>
                        <Link
                          to="/dashboard/users"
                          onClick={handleUsersButtonActive}
                          className={
                            !isUsersButtonActive
                              ? "text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              : "bg-gray-800 text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          }
                        >
                          <UsersIcon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          Users
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}
                    {isAdmin || isAgent ? (
                      <li>
                        <Link
                          to="/dashboard/tickets"
                          onClick={handleTicketsButtonActive}
                          className={
                            !isTicketsButtonActive
                              ? "text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              : "bg-gray-800 text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          }
                        >
                          <DocumentDuplicateIcon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          Tickets
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <></>
              {/* <form className="relative flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  type="search"
                  name="search"
                />
              </form> */}
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    {/* <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src="#"
                      alt=""
                    /> */}
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                        aria-hidden="true"
                      >
                        {firstName} {lastName} - {role}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Link
                        className="bg-gray-50 block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer"
                        // to={`/dashboard/users/profile/${userId}`}
                        to={`/dashboard/profile/${userId}`}
                      >
                        Profile
                      </Link>
                      <Link
                        className="bg-gray-50 block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer"
                        onClick={sendLogout}
                      >
                        Logout
                      </Link>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default DashboardLayout