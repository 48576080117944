
import './App.css';
import {Routes,Route,useParams,useNavigate} from "react-router-dom"
import Layout from "./Components/Layout"
import Public from "./Components/Public"
import Login from "./features/auth/Login"
import Register from "./features/auth/Register"
import DashboardLayout from "./Components/DashboardLayout"
import Welcome from "./features/auth/Welcome"
import TicketsList from "./features/tickets/TicketsList"
import EditTicket from "./features/tickets/EditTicket"
import ErrorPage from "./Components/ErrorPage"
import UsersList from "./features/users/UsersList"
import EditUserForm from "./features/users/EditUserForm"
import EditUser from "./features/users/EditUser"
import Prefetch from "./features/auth/Prefetch"
import TicketsListWithFilter from "./features/tickets/TicketsListWithFilter"
import PersistLogin from './features/auth/PersistLogin';
import {Roles} from "./config/Roles"
import RequireAuth from "./features/auth/RequireAuth"
import RequireAuthAdmin from "./features/auth/RequireAuthAdmin"
import RequireAuthAgent from "./features/auth/RequireAuthAgent"
import RequireAuthAdminOrAgent from './features/auth/RequireAuthAgentOrAdmin';
import EditProfileForm from "./features/users/EditProfileForm";
import IdleTimer from "react-idle-timer";

function App() {
  const navigate = useNavigate();

  const handleOnIdle = () => {
    navigate("/login");
  };
  return (
    <IdleTimer timeout={1000 * 60 * 15} onIdle={handleOnIdle}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Public />} />
          <Route path="login" element={<Login />} />
          <Route element={<RequireAuthAdmin />}>
            <Route path="register" element={<Register />} />
          </Route>

          {/* Dashbaord start */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
              <Route element={<Prefetch />}>
                <Route path="dashboard" element={<DashboardLayout />}>
                  <Route index element={<Welcome />} />
                  {/*Ticket-with-filter start*/}
                  <Route element={<RequireAuthAdminOrAgent />}>
                    {/* <Route path="tickets-with-filter">
                    <Route index element={<TicketsListWithFilter />} />
                    <Route path=":ticketId" element={<EditTicket />} />
                  </Route> */}
                    {/*Ticket-with-filter end*/}

                    {/*Tickets start*/}

                    <Route path="tickets">
                      <Route index element={<TicketsList />} />
                      <Route path=":ticketId" element={<EditTicket />} />
                    </Route>
                    <Route path="profile">
                      <Route path=":userId" element={<EditProfileForm />} />
                    </Route>
                  </Route>

                  {/*Tickets end*/}
                  {/*Users start*/}
                  <Route element={<RequireAuthAdmin />}>
                    <Route path="users">
                      {/* <Route path="register" element={<Register />} /> */}
                      <Route index element={<UsersList />} />
                      <Route path=":userId" element={<EditUser />} />
                      {/* <Route path="profile">
                        <Route path=":userId" element={<EditProfileForm />} />
                      </Route> */}
                    </Route>
                  </Route>
                </Route>
                {/*Users stendart*/}
              </Route>
            </Route>
          </Route>

          {/* Dashbaord End */}

          <Route path="/*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </IdleTimer>
  );
}

export default App;
