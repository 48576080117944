import React from 'react'

const Content=()=>{
    return (
      <>
        <div className="bg-white">
          <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Welcome to Price Promise Ticket System
                <br />
              
              </h2>
             
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="/login"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Login to your account
                </a>
                {/* <a
                  href="/register"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Register your account <span aria-hidden="true">→</span>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

const Public = () => {
  return (
    <Content  />
  )
}

export default Public