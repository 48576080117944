import { useGetUsersQuery } from "./usersApiSlice";
import User from "./User";
import { useState } from "react";

export default function UsersList() {
  // const [users, setUsers] = useState([]);
  // Add these states at the top of your component
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUsersQuery();

  let content;

  if (isLoading) content = <p>Loading Users...</p>;

  if (isError) {
    content = <p>{error?.data?.message}</p>;
  }
  let pagedIds;
  if (isSuccess) {
    const { ids, entities } = users;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    pagedIds = ids.slice(indexOfFirstItem, indexOfLastItem);

    const PaginationMark = () => {
      return (
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{currentPage} </span> to{" "}
            <span className="font-medium">
              {Math.ceil(ids.length / itemsPerPage)}
            </span>{" "}
            of <span className="font-medium">{ids.length}</span> results
          </p>
        </div>
      );
    };

    const NextPageButton = () => {
      if (currentPage < Math.ceil(ids.length / itemsPerPage)) {
        return (
          <button
            className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        );
      } else {
        return (
          <button
            className="hidden relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled
          >
            Next
          </button>
        );
      }
    };

    const PreviousPageButton = () => {
      if (currentPage > 1) {
        return (
          <button
            className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
        );
      } else {
        return (
          <button
            className="hidden relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled
          >
            Previous
          </button>
        );
      }
    };

    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
    const tableContent = pagedIds?.length
      ? pagedIds.map((userId) => <User userId={userId} key={userId} />)
      : null;

    content = (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Users
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the users in team, including their User ID, E-mail
              and Role
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            {/* <button
                 type="button"
                 className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
               >
                 Download CSV
               </button> */}
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        User ID
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Role
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      ></th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {tableContent}
                  </tbody>
                </table>
              </div>
              <nav
                className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
              >
                <PaginationMark />

                <div className="flex flex-1 justify-between sm:justify-end">
                  <PreviousPageButton />
                  <span className="ml-5"></span>
                  <NextPageButton />
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return content;
}
