import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireAuth = () => {
  const location = useLocation();
  const { role } = useAuth();



  const content=(role==="Admin"||role==="Agent"||role==="New")?(<Outlet/>):(<Navigate to="/login" state={{ from: location }} replace />);

  return content;
};



export default RequireAuth;
