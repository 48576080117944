import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const ticketsAdapter = createEntityAdapter({});

const initialState = ticketsAdapter.getInitialState();

export const ticketsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTickets: builder.query({
      query: () => `/tickets`,
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
      transformResponse: (responseData) => {
        const loadedTickets = responseData.map((ticket) => {
          ticket.id = ticket.ticketId;
          return ticket;
        });
        return ticketsAdapter.setAll(initialState, loadedTickets);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Ticket", ticketId: "LIST" },
            ...result.ids.map((id) => ({ type: "Ticket", id })),
          ];
        } else return [{ type: "Ticket", ticketId: "LIST" }];
      },
    }),
    //add a new ticket
    addNewTicket: builder.mutation({
      query: (initialTicketData) => ({
        url: `/tickets`,
        method: "POST",
        body: {
          ...initialTicketData,
        },
      }),
      invalidatesTag: [
        {
          type: "Ticket",
          ticketId: "LIST",
        },
      ],
    }),
    //update a ticket
    updateTicket: builder.mutation({
      query: (initialTicketData) => ({
        url: `/tickets`,
        method: "PATCH",
        body: {
          ...initialTicketData,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Ticket", id: arg.ticketId },
      ],
    }),
    //delete a ticket
    deleteTicket: builder.mutation({
      query: ({ ticketId }) => ({
        url: `/tickets`,
        method: "DELETE",
        body: { ticketId },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Ticket", id: arg.ticketId },
      ],
    }),
    //update isHiddenTicket Status
    updateHiddenTicket: builder.mutation({
      query: (initialTicketData) => ({
        url: `/tickets/is-hidden-ticket`,
        method: "PATCH",
        body: {
          ...initialTicketData,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Ticket", id: arg.ticketId },
      ],
    }),
  }),
});

export const { useGetTicketsQuery,useUpdateTicketMutation,useDeleteTicketMutation,useUpdateHiddenTicketMutation } = ticketsApiSlice;
// returns the query result object
export const selectTicketsResult = ticketsApiSlice.endpoints.getTickets.select();

// creates memoized selector
const selectTicketsData = createSelector(
  selectTicketsResult,
  (ticketsResult) => ticketsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllTickets,
  selectById: selectTicketById,
  selectIds: selectTicketIds,
  // Pass in a selector that returns the tickets slice of state
} = ticketsAdapter.getSelectors(
  (state) => selectTicketsData(state) ?? initialState
);
