import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import {apiSlice} from "../../app/api/apiSlice"


const usersAdapter=createEntityAdapter({})

const initialState = usersAdapter.getInitialState()

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => `/users`,
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
      transformResponse: (responseData) => {
        const loadedUsers = responseData.map((user) => {
          user.id = user.userId;
          return user;
        });
        return usersAdapter.setAll(initialState, loadedUsers);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "User", userId: "LIST" },
            ...result.ids.map((id) => ({ type: "User", id })),
          ];
        } else return [{ type: "User", userId: "LIST" }];
      },
    }),
    //add a new user
    addNewUser: builder.mutation({
      query: (initialUserData) => ({
        url: `/users/registration`,
        method: "POST",
        body: {
          ...initialUserData,
        },
      }),
      invalidatesTag: [
        {
          type: "User",
          userId: "LIST",
        },
      ],
    }),
    //update a user
    updateUser: builder.mutation({
      query: (initialUserData) => ({
        url: `/users/`,
        method: "PATCH",
        body: {
          ...initialUserData,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "User", id: arg.userId },
      ],
    }),
    //delete a user
    deleteUser: builder.mutation({
      query: ({ userId }) => ({
        url: `/users`,
        method: "DELETE",
        body: { userId },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "User", id: arg.userId },
      ],
    }),
  }),
});


export const {
  useGetUsersQuery,
  useAddNewUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = usersApiSlice;
// returns the query result object
export const selectUsersResult = usersApiSlice.endpoints.getUsers.select()

// creates memoized selector
const selectUsersData = createSelector(
    selectUsersResult,
    usersResult => usersResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectUserIds
  // Pass in a selector that returns the users slice of state
} = usersAdapter.getSelectors(
  (state) => selectUsersData(state) ?? initialState
);

