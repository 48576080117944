import Ticket from "./Ticket";
import TicketShowNotFlaggedTicket from "./TicketShowNotFlaggedTicket";
import { useState, useEffect } from "react";
import { useGetTicketsQuery } from "./ticketsApiSlice";
import { useGetUploadImagesQuery } from "../upload/uploadImagesApiSlice";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import axios from "axios";

export default function TicketsList() {
  const navigate = useNavigate();
  const {
    data: tickets,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetTicketsQuery();
  // console.log("tickets", tickets);
  // const [jsonData, setJsonData] = useState(tickets.entities);

  // const {
  //   data: images,
  //   isLoading: isGetAllImagesLoading,
  //   isSuccess: isGetAllImagesSuccess,
  //   isError: isGetAllImagesError,
  //   error: imagesError,
  // } = useGetUploadImagesQuery();

  // console.log("imgs",images);

  const [ticketsListdata, setTicketsListData] = useState(null);
  const [showAllTicket, setShowAllTicket] = useState(false);

  const [csvData, setCsvData] = useState(null);
  const [showConvertCSVButton, setShowConvertCSVButton] = useState(false);

  const [query, setQuery] = useState("");

  // Add these states at the top of your component
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const onClickToggleShowAllTicket = () => {
    setShowAllTicket(!showAllTicket);
  };

  let content;

  if (isLoading) content = <p>Loading Tickets...</p>;

  if (isError) content = <p>{error?.data?.message}</p>;

  if (isError) content = <p>{error?.data?.message}</p>;

  if (isSuccess) {
    const { ids, entities } = tickets;

    // console.log("entiy",entities["5712f194-32a1-4d8f-94cd-848b59857fa0"]["isHiddenTicket"]);

    let filteredIds;
    // Add this to paginate your filteredIds
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    if (query === "") {
      filteredIds = [...ids];
      filteredIds = filteredIds.slice(indexOfFirstItem, indexOfLastItem);
    } else {
      const filteredResult = ids.map((ticketId) => {
        //loop though ticketId and keys=["ticketId","email","cratedAt","sku","ticketStatus"] get the value of the entities[ticketId][key] if it match the query than return the ticketId
        const keys = [
          "ticketId",
          "email",
          "orderNumber",
          "sku",
          "ticketStatus",
          "createdAt",
          "updatedAt",
        ];
        return keys.some((key) => {
          return entities[ticketId][key]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase());
        });
      });
      //match up all the true value and reture the ticketId

      //compare the filteredResult with ids and return the ticketId
      filteredIds = ids.filter((ticketId, index) => {
        return filteredResult[index];
      });
      // filteredIds = filteredIds.slice(indexOfFirstItem, indexOfLastItem);
    }

    // Add this to paginate your filteredIds

    console.log("filteredIds", filteredIds);

    const convertToJson = () => {
      const { entities } = tickets;
      if (!entities) return;

      const fields = [
        "ticketId",
        "email",
        "orderNumber",
        "sku",
        "matchPrice",
        "matchPriceDate",
        "priceMatchURL",
        "rejectComment",
        "approvalComment",
        "ticketStatus",
        "createdAt",
        //  "updatedAt",
        "isHiddenTicket",
        "lastUpdatedPerson",
      ];

      let csvContent;
      if (query === "") {
        csvContent = Object.values(entities).map((entity) =>
          fields.map((field) => {
            let value = entity[field];
            if (typeof value === "string" && /^[=+@-]/.test(value)) {
              value = "'" + value;
            }
            return value;
          })
        );
      } else {
        csvContent = filteredIds.map((ticketId) =>
          fields.map((field) => {
            let value = entities[ticketId][field];
            if (typeof value === "string" && /^[=+@-]/.test(value)) {
              value = "'" + value;
            }
            return value;
          })
        );
      }

      //  const csvContent = Object.values(entities).map((entitie) =>
      //    fields.map((field) => entitie[field])
      //  );

      console.log("csv", csvContent);

      const csv = Papa.unparse({
        fields: fields,
        data: csvContent,
      });

      setCsvData(csv);
      setShowConvertCSVButton(!showConvertCSVButton);
    };

    const downloadCsv = () => {
      if (csvData) {
        const blob = new Blob([csvData], { type: "text/csv" });
        const blobUrl = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        let date = new Date();
        let dateString = date.toISOString().slice(0, 15);
        let filename = `tickets-${dateString}.csv`;
        downloadLink.href = blobUrl;
        downloadLink.download = filename;
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(blobUrl);
      }

      setShowConvertCSVButton(!showConvertCSVButton);
    };

    // Add a function to handle page change
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const tableContent = showAllTicket
      ? filteredIds?.length
        ? filteredIds?.length &&
          filteredIds.map((ticketId) => (
            <TicketShowNotFlaggedTicket ticketId={ticketId} key={ticketId} />
          ))
        : null
      : filteredIds?.length
      ? filteredIds?.length &&
        filteredIds.map((ticketId) => (
          <Ticket ticketId={ticketId} key={ticketId} />
        ))
      : null;
    // const tableContent = null

    const PaginationMark = () => {
      if (query === "") {
        return (
          <div className="hidden sm:block">
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{currentPage} </span> to{" "}
              <span className="font-medium">
                {Math.ceil(ids.length / itemsPerPage)}
              </span>{" "}
              of <span className="font-medium">{ids.length}</span> results
            </p>
          </div>
        );
      } else {
        return (
          <div className="hidden sm:block">
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{currentPage} </span> to{" "}
              <span className="font-medium">
                {Math.ceil(filteredIds.length / itemsPerPage)}
              </span>{" "}
              of <span className="font-medium">{filteredIds.length}</span>{" "}
              results
            </p>
          </div>
        );
      }
    };

    const NextPageButton = () => {
      if (query === "") {
        if (currentPage < Math.ceil(ids.length / itemsPerPage)) {
          return (
            <button
              className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          );
        } else {
          return (
            <button
              className="hidden relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled
            >
              Next
            </button>
          );
        }
      }
    };

    const PreviousPageButton = () => {
      if (query === "") {
        if (currentPage > 1) {
          return (
            <button
              className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
          );
        } else {
          return (
            <button
              className="hidden relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled
            >
              Previous
            </button>
          );
        }
      }
    };

    content = (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Tickets
            </h1>
            {/* <p className="mt-2 text-sm text-gray-700">
              A list of all the tickets in including their E-mail, Order
              Number,SKU and Match Price.
            </p> */}
          </div>

          <div>
            <input
              type="text"
              name="query"
              id="query"
              onChange={(e) => setQuery(e.target.value)}
              className="block w-96 h-10 rounded-md border-1 py-4.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="  Search email, order number, sku, status etc..."
            />
            {/* <button
                
                onClick={search}
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >Search</button> */}
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            {showAllTicket ? (
              <button
                type="checkbox"
                onClick={onClickToggleShowAllTicket}
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Show all tickets
              </button>
            ) : (
              <button
                type="checkbox"
                onClick={onClickToggleShowAllTicket}
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Unprocessed tickets
              </button>
            )}
          </div>
          {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              onClick={() => {
                navigate("/dashboard/tickets-with-filter");
              }}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Filter
            </button>
          </div> */}
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              onClick={convertToJson}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Convert CSV
            </button>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            {showConvertCSVButton ? (
              <button
                type="button"
                onClick={downloadCsv}
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Download CSV
              </button>
            ) : (
              <button
                type="button"
                onClick={downloadCsv}
                className="bg-blue-500 rounded-md text-white font-bold px-3 py-2 text-center text-sm opacity-50 cursor-not-allowed"
                disabled
              >
                Download CSV
              </button>
            )}
          </div>
        </div>

        {/* <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form className="relative flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  type="search"
                  name="search"
                />
              </form>
        </div> */}
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Order Number
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        SKU
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Match Price
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Multiple Claim
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Created Date
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {tableContent}
                  </tbody>
                </table>
              </div>
              {/* <div className="mt-5 sm:ml-16 sm:mt-0 sm:flex-none"></div>
              <div className="mt-5 sm:ml-16 sm:mt-0 sm:flex-none"></div>
              <div className="mt-5 sm:ml-16 sm:mt-0 sm:flex-none">
                <PreviousPageButton />
                <span className="ml-5"></span>
                <NextPageButton />
                <span className="ml-5"></span>
                <PaginationMark />
              </div> */}
              <nav
                className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
              >
                <PaginationMark />

                <div className="flex flex-1 justify-between sm:justify-end">
                  <PreviousPageButton />
                  <span className="ml-5"></span>
                  <NextPageButton />
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }

  //loop thought the tickets data and display it

  return content;
}
